import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { doc, updateDoc } from "firebase/firestore";
import { format } from "date-fns";

import { db, functions } from "../../../utils/firebase";
import {
  fetchBookingFromID,
  fetchPurchasedItem,
  fetchUserInfo,
} from "../../../Services/FirebaseService";
import Video from "../../../Base/Video";
import UserBanner from "../../../Base/UserBanner";
import SignForm from "../../../Base/Sign/SignForm";
import ImageView from "../../../Base/ImageView";

var productResource = null;
var userInfoResource = null;
var bookingItemResource = null;

export default function BookingStatus() {
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  let { username, item_id } = useParams();

  if (productResource == null) {
    productResource = fetchPurchasedItem("booking", item_id);
  }

  const purchasedProductItem = productResource.read();

  if (!purchasedProductItem.item) {
    window.open("/" + username, "_self");
  }

  if (userInfoResource == null) {
    userInfoResource = fetchUserInfo(purchasedProductItem.uid);
  }

  if (bookingItemResource == null) {
    bookingItemResource = fetchBookingFromID(
      purchasedProductItem.uid,
      purchasedProductItem.item.bookingServiceID
    );
  }

  const user = userInfoResource.read();
  user.username = username;

  const booking = purchasedProductItem.item;
  const bookingItem = bookingItemResource.read().booking;

  const date = new Date();
  if (typeof booking.date === "string" || booking.date instanceof String)
    date.setTime(parseInt(booking.date));
  else date.setTime(booking.date);

  var confirmColor = "";

  switch (booking.status) {
    case "COMPLETED":
      confirmColor = "#545963";
      break;

    case "CONFIRMED":
      confirmColor = "#06844F";
      break;

    default:
      confirmColor = "#B01121";
      break;
  }

  const cancelBooking = async () => {
    setIsCancelLoading(true);
    const ref = doc(db, "users", user.uid, "bookingEvents", booking.id);

    await updateDoc(ref, {
      status: "CANCELED",
    });

    if (booking.paymentIntentID) {
      const itemCallable = httpsCallable(functions, "refundStripePayment");

      await itemCallable({
        paymentIntentID: booking.paymentIntentID,
      });
    }
    setIsCancelLoading(false);

    window.open(`/${username}/booking/status/${item_id}`, "_self");
  };

  return (
    <>
      <div className="mainInfo">
        <UserBanner user={user} hideMobile={true} />
        <Link to={`/${username}`} className="mainMore__back">
          <img src="/images/icons/arrow-left.svg" alt="" />
        </Link>

        <SignForm
          active={loginModalVisible}
          setActive={setLoginModalVisible}
          reloadPage={true}
        />

        <div className="auto__container">
          <div className="widget__inner more">
            <div className="widget__inner-general">
              <div className="widget__inner-request-tick">
                {booking.status === "PLEASE_CONFIRM" && (
                  <img src="/images/icons/tick.svg" alt="" />
                )}
                {booking.status === "CONFIRMED" && (
                  <img src="/images/icons/tick-green.svg" alt="" />
                )}
                {booking.status === "COMPLETED" && (
                  <img src="/images/icons/tick-green.svg" alt="" />
                )}
                {booking.status === "CANCELED" && (
                  <img src="/images/icons/canceled.svg" alt="" />
                )}
              </div>

              {booking.status === "PLEASE_CONFIRM" && (
                <div className="widget__inner-request-title">Request Sent</div>
              )}
              {booking.status === "CONFIRMED" && (
                <div className="widget__inner-request-title">Confirmed</div>
              )}
              {booking.status === "COMPLETED" && (
                <div className="widget__inner-request-title">Completed</div>
              )}
              {booking.status === "CANCELED" && (
                <div className="widget__inner-request-title">Canceled</div>
              )}

              {booking.status === "PLEASE_CONFIRM" && (
                <div className="widget__inner-request-text">
                  You will be notified when {user.info.fullName} confirms.
                </div>
              )}

              {booking.status === "CONFIRMED" && (
                <div className="widget__inner-request-text">
                  {user.info.fullName} has confirmed this meeting.
                </div>
              )}

              {booking.status === "COMPLETED" && (
                <div className="widget__inner-request-text">
                  This meeting has been successfully completed.
                </div>
              )}

              {booking.status === "CANCELED" && (
                <div className="widget__inner-request-text">
                  This meeting has been canceled.
                </div>
              )}

              {user.headshot && user.headshot.showHeadshot && (
                <div
                  className="widget__inner-general-avatar"
                  style={{ borderRadius: 100 }}
                >
                  <img src={user.headshot.headShotPath} alt="" />
                </div>
              )}
              {(!user.headshot || !user.headshot.showHeadshot) && (
                <div className="widget__inner-general-avatar">
                  <img
                    src="/images/initialsBackground.png"
                    style={{ objectFit: "fill", border: 0 }}
                    alt=""
                  />
                  <div
                    className="widget__inner-info-name"
                    style={{
                      position: "absolute",
                      fontWeight: "normal",
                      fontSize: 24,
                      color: "#545963",
                    }}
                  >
                    MM
                  </div>
                </div>
              )}

              <div className="widget__inner-general-name statusName">
                {user.info.fullName}
              </div>
              <div className="widget__inner-general-prof">
                {user.info.workPlace.trim() || user.info.title.trim()}
              </div>
              <div
                style={{ color: confirmColor, marginTop: 12, marginBottom: 40 }}
                className={"widget__inner-general-pending active"}
              >
                <span style={{ backgroundColor: confirmColor }}></span>
                {booking.status === "PLEASE_CONFIRM" && "Pending confirmation"}
                {booking.status === "CONFIRMED" && "Confirmed"}
                {booking.status === "COMPLETED" && "Completed"}
                {booking.status === "CANCELED" && "Canceled"}
              </div>
              <div className="widget__inner-general-confirmed">
                <span></span> Confirmed
              </div>
              <div className="widget__inner-general-row">
                {booking.rate && booking.rate > 0 && (
                  <div className="widget__inner-general-col">
                    Rate:
                    <span>{"$" + booking.rate}</span>
                  </div>
                )}
                <div className="widget__inner-general-col">
                  Date
                  <span>{format(date, "eee, MMM dd")}</span>
                </div>
                <div className="widget__inner-general-col">
                  Time:
                  <span>
                    {format(date, "p").replace("AM", "am").replace("PM", "pm")}
                  </span>
                </div>
              </div>

              <div
                className="widget__inner-general-title statusTitle"
                style={{ fontWeight: 600 }}
              >
                Booking type
              </div>

              {[booking].map((booking) => {
                if (booking.videoPath) {
                  return (
                    <div
                      key={booking.id}
                      className="widget__inner-work"
                      style={{ padding: 0 }}
                    >
                      <div
                        className="widget__inner-work-body bodyStatus"
                        style={{ paddingTop: 0 }}
                      >
                        <div
                          className="widget__inner-work-body-video-div"
                          style={{ marginTop: 20, display: "flex" }}
                        >
                          <Video
                            showFullscreen={true}
                            isFullscreenBottom={true}
                            addClass={"widget__inner-work-body-video"}
                            poster={
                              "https://image.mux.com/" +
                              booking.videoPath +
                              "/thumbnail.png"
                            }
                            url={
                              "https://stream.mux.com/" +
                              booking.videoPath +
                              ".m3u8"
                            }
                          />
                        </div>
                        <div
                          className="widget__inner-work-body-content contentStatus"
                          style={{
                            marginTop: 20,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="widget__inner-work-body-block">
                            <div>
                              <div className="widget__inner-work-body-content-title">
                                {booking.title}
                              </div>
                              <div className="widget__inner-work-body-content-text">
                                {booking.description}
                              </div>
                            </div>
                          </div>
                          <br />
                          <span
                            style={{
                              display: "flex",
                              flexGrow: 1,
                              alignSelf: "self-end",
                              width: "100%",
                            }}
                          >
                            <div
                              className="widget__inner-work-body-content-more"
                              style={{ display: "flex", alignItems: "end" }}
                            >
                              {booking.rate && booking.rate > 0 && (
                                <div
                                  className="widget__inner-type-footer-text"
                                  style={{ marginRight: 20 }}
                                >
                                  Rate
                                  <span>{"$" + booking.rate}</span>
                                </div>
                              )}
                              <div className="widget__inner-type-footer-text">
                                Duration
                                <span>{booking.duration}</span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                } else if (booking.imagePath) {
                  return (
                    <div
                      key={booking.id}
                      className="widget__inner-work"
                      style={{ padding: 0 }}
                    >
                      <div
                        className="widget__inner-work-body bodyStatus"
                        style={{ paddingTop: 0 }}
                      >
                        <div
                          className="widget__inner-work-body-video-div"
                          style={{ marginTop: 20, display: "flex" }}
                        >
                          <ImageView
                            addClass={"widget__inner-type-video"}
                            url={booking.imagePath}
                          />
                        </div>
                        <div
                          className="widget__inner-work-body-content contentStatus"
                          style={{
                            marginTop: 20,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="widget__inner-work-body-block">
                            <div>
                              <div className="widget__inner-work-body-content-title">
                                {booking.title}
                              </div>
                              <div className="widget__inner-work-body-content-text">
                                {booking.description}
                              </div>
                            </div>
                          </div>
                          <br />
                          <span
                            style={{
                              display: "flex",
                              flexGrow: 1,
                              alignSelf: "self-end",
                              width: "100%",
                            }}
                          >
                            <div
                              className="widget__inner-work-body-content-more"
                              style={{ display: "flex", alignItems: "end" }}
                            >
                              {booking.rate && booking.rate > 0 && (
                                <div
                                  className="widget__inner-type-footer-text"
                                  style={{ marginRight: 20 }}
                                >
                                  Rate
                                  <span>{"$" + booking.rate}</span>
                                </div>
                              )}
                              <div className="widget__inner-type-footer-text">
                                Duration
                                <span>{booking.duration}</span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={booking.id}
                      className="widget__inner-work"
                      style={{ padding: 0 }}
                    >
                      <div
                        className="widget__inner-work-body bodyStatus"
                        style={{ paddingTop: 0 }}
                      >
                        <div
                          className="widget__inner-work-body-content contentStatus"
                          style={{
                            marginTop: 20,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="widget__inner-work-body-block">
                            <div>
                              <div className="widget__inner-work-body-content-title">
                                {booking.title}
                              </div>
                              <div className="widget__inner-work-body-content-text">
                                {booking.description}
                              </div>
                            </div>
                          </div>
                          <br />
                          <span
                            style={{
                              display: "flex",
                              flexGrow: 1,
                              alignSelf: "self-end",
                              width: "100%",
                            }}
                          >
                            <div
                              className="widget__inner-work-body-content-more"
                              style={{ display: "flex", alignItems: "end" }}
                            >
                              {booking.rate && booking.rate > 0 && (
                                <div
                                  className="widget__inner-type-footer-text"
                                  style={{ marginRight: 20 }}
                                >
                                  Rate
                                  <span>{"$" + booking.rate}</span>
                                </div>
                              )}
                              <div className="widget__inner-type-footer-text">
                                Duration
                                <span>{booking.duration}</span>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              <div
                className="widget__inner-status-title"
                style={{ fontSize: 16 }}
              >
                Location
              </div>
              <div className="widget__inner-status-text">
                {booking.meetingLink
                  ? booking.meetingLink
                  : "You will receive a link prior to the scheduled meeting."}
              </div>

              {booking.status !== "COMPLETED" &&
                booking.status !== "CANCELED" && (
                  <div
                    className="widget__inner-status-title"
                    style={{ fontSize: 16 }}
                  >
                    Can't make this meeting?
                  </div>
                )}
              {booking.status === "CANCELED" && (
                <div
                  className="widget__inner-status-title"
                  style={{ fontSize: 16 }}
                >
                  Options
                </div>
              )}

              {booking.status !== "COMPLETED" && (
                <Link
                  to={"/" + user.username + "/booking/pages/date"}
                  state={{
                    booking: bookingItem,
                    user: user,
                    publicItemId: item_id,
                    bookingEventId: booking.id,
                    wasBookingCanceled: booking.status === "CANCELED",
                  }}
                  style={{ height: 48, padding: 0, borderRadius: 50 }}
                  className="widget__footer-inner-reschedule"
                >
                  Reschedule
                </Link>
              )}

              {booking.status !== "COMPLETED" &&
                booking.status !== "CANCELED" && (
                  <button
                    onClick={() => cancelBooking()}
                    style={{ borderRadius: 50 }}
                    className="widget__footer-inner-cancel"
                    disabled={isCancelLoading}
                  >
                    {isCancelLoading && <span className="loader"></span>}
                    Cancel booking
                  </button>
                )}

              {(booking.status === "COMPLETED" ||
                booking.status === "CANCELED") && (
                <Link
                  to={"/" + user.username + "/booking"}
                  style={{ height: 48, padding: 0, borderRadius: 50 }}
                  className="widget__footer-inner-reschedule"
                >
                  See booking types
                </Link>
              )}
            </div>
            <hr className="widget__inner-line" />
          </div>
        </div>
      </div>
      <div className="genBtn" style={{ marginTop: 0, marginBottom: 64 }}>
        Powered by{" "}
        <img
          className="dossifooterlogo"
          src="/images/icons/PoweredbyDossi.svg"
          alt="logo"
        />
      </div>
    </>
  );
}
