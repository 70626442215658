import { useLocation } from "react-router";
import { useCookies } from "react-cookie";
import React, { useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../utils/firebase";

import UserBanner from "./UserBanner";

const Main = (props) => {
  const [cookies] = useCookies(["username", "uid", "email", "full_name"]);

  const location = useLocation();
  const setIsFollowing = React.useState(false)[1];
  const isBooking = location.pathname === `/${props.user.username}/booking`;

  useEffect(() => {
    const checkIsFollowing = async () => {
      const following =
        (
          await getDocs(
            query(
              collection(db, "users", cookies?.uid, "networking"),
              where("following", "array-contains", props?.user?.uid)
            )
          )
        ).size > 0;
      setIsFollowing(following);
    };

    if (cookies?.uid) checkIsFollowing();

    window.scrollTo(0, 0);
  }, [cookies.uid, location, props.user.uid, setIsFollowing]);

  return (
    <div className="mainInfo overviewBlock">
      <UserBanner
        user={props.user}
        hideMobile={isBooking ? "hide_mobile" : ""}
      />
      <div className="auto__container">{props.children}</div>
    </div>
  );
};

export default Main;
