import { doc, setDoc } from "firebase/firestore";

import { db } from "../../utils/firebase";

export const addUserToDB = (user) => {
  const userData = {
    email: user.email,
    fullName: user.displayName || user.email,
  };

  const userRef = doc(db, "users", user.uid, "info", "userInfo");
  const userIdRef = doc(db, "usernames", user.username || user.email);
  const userImageRef = doc(db, "users", user.uid, "headshots", "headshot");

  setDoc(userRef, userData);
  setDoc(userIdRef, { uid: user.uid });

  if (user.photoURL) {
    setDoc(userImageRef, {
      headShotPath: user.photoURL,
      showHeadshot: true,
    });
  }
};
