import React from "react";

import { useCallback } from "react";

import { useState } from "react";

import { getInitialsUser } from "../utils/utils";

const UserImage = ({
  currentUser,
  setIsModalOpen,
  isModalOpen,
  onError,
  ...props
}) => {
  const [isError, setIsError] = useState();

  const handleError = useCallback(
    (e, currentUser) => {
      if (onError) onError(e, currentUser.email);
      setIsError(true);
    },
    [onError]
  );

  if (isError || !currentUser.photoURL) {
    return (
      <div className="initials">
        {getInitialsUser(currentUser.displayName || currentUser.email)}
      </div>
    );
  }

  return (
    <img
      src={currentUser.photoURL}
      alt="userImage"
      className="userImage"
      onError={(e) => handleError(e, currentUser)}
      {...props}
    />
  );
};

export default UserImage;
