/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCookies } from "react-cookie";
import React, { useState } from "react";
import { Magic } from "magic-sdk";

import { httpsCallable } from "firebase/functions";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithCustomToken,
} from "firebase/auth";

import { addUserToDB } from "../../utils/utils";
import { auth, db, functions } from "../../utils/firebase";
import { setLoggedInUser } from "../../Services/FirebaseService";

const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY);
const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const fbProvider = new FacebookAuthProvider();
fbProvider.setCustomParameters({
  display: "popup",
});

export default function SignForm({
  active,
  setActive,
  defaultEmail,
  onLoginComplete,
  setIsAuthSuccessModal,
}) {
  const [, setCookie] = useCookies(["username", "uid", "email", "full_name"]);

  const [tab, setTab] = useState("signin");
  const [emailSentStatus, setEmailSentStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form, setForm] = useState({
    email: defaultEmail ? defaultEmail : "",
  });
  const [validation, setValidation] = useState({
    email: true,
  });

  const onMagicLinkClick = async (e) => {
    e.preventDefault();
    const newState = {};
    let isValid = true;

    Object.keys(form).forEach((key) => {
      if (form[key] === "") {
        newState[key] = false;
        newState["emailError"] = "Can’t leave field blank";
        isValid = false;
      } else if (!validateEmail(form[key])) {
        newState[key] = false;
        newState["emailError"] = "Please enter a valid email";
        isValid = false;
      } else {
        newState[key] = true;
      }
    });

    setValidation(newState);

    if (isValid) {
      setIsLoading(true);
      try {
        const didToken = await magic.auth.loginWithMagicLink({
          email: form.email,
          showUI: true,
        });

        const fbAuth = httpsCallable(functions, "auth");
        let res = (await fbAuth({ didToken })).data;

        await signInWithCustomToken(auth, res.token)
          .then((result) => {
            const user = result.user;
            loginUser(user);
            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const signInGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;

        addUserToDB(user);
        loginUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signInFacebook = () => {
    signInWithPopup(auth, fbProvider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = FacebookAuthProvider.credentialFromResult(result);

        // const token = credential.accessToken;
        const user = result.user;

        addUserToDB(user);
        loginUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signInApple = () => {
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = OAuthProvider.credentialFromResult(result);

        // const token = credential.accessToken;
        const user = result.user;

        addUserToDB(user);
        loginUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loginUser = async (user) => {
    const userDocs = (
      await getDocs(
        query(collection(db, "usernames"), where("uid", "==", user.uid))
      )
    ).docs;

    if (userDocs.length !== 0) {
      const username = userDocs[0].id;
      const userInfo = await getDoc(
        doc(db, "users", user.uid, "info", "userInfo")
      );

      user.username = username;
      user.userInfo = userInfo;
    } else {
      user.username = user.displayName;
    }

    setCookie("username", user.username, { path: "/" });
    setCookie("uid", user.uid, { path: "/" });
    setCookie("email", user.email, { path: "/" });

    if (user.userInfo) {
      setCookie("full_name", user.userInfo.fullName, { path: "/" });
    }
    setIsAuthSuccessModal(true);
    setActive(false);
    setLoggedInUser(user);

    if (onLoginComplete) onLoginComplete();
  };

  const closeModal = (e) => {
    setEmailSentStatus(false);

    if (e.target === e.currentTarget) {
      setTab("signin");
      setActive(false);
    }

    document.body.classList.remove("active");
  };

  const updateState = (data) => {
    setForm({ ...form, ...data });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <div className={`modal ${active ? "active" : ""}`} onClick={closeModal}>
      <div className="modal__inner">
        <div
          className="modal__close"
          onClick={() => {
            setTab("signin");
            setActive(false);
          }}
        >
          <img src="/images/sign/close.svg" alt="" />
        </div>
        {tab === "email" && (
          <form className="sign">
            <div className="sign__title">Welcome!</div>
            <div className="sign__text m24">
              Enter your email and we’ll send you a magic link to sign in or
              sign up.
            </div>
            <div
              className={`sign__input ${validation.email ? "" : "invalid"} ${
                emailSentStatus === true ? "valid" : ""
              }`}
            >
              <input
                onChange={(e) => updateState({ email: e.target.value })}
                value={form.email}
                type="text"
                placeholder="Email address"
              />
              <div className="error">{validation.emailError}</div>
            </div>
            {emailSentStatus && (
              <div className="success">
                We've sent a magic link to your email!
              </div>
            )}
            <button
              className="sign__submit"
              onClick={onMagicLinkClick}
              style={validation.email ? {} : { marginTop: 36 }}
              disabled={isLoading}
            >
              {isLoading && <span className="loader"></span>} LOGIN
            </button>

            <div className="sign__text sm" style={{ marginTop: 56 }}>
              By continuing you agree to Dossi, Inc.
              <a href="#">Terms & Conditions</a> and{" "}
              <a href="#">Privacy Policy</a>
            </div>
          </form>
        )}
        {tab === "signin" && (
          <div className="sign">
            <div className="sign__title">Welcome!</div>
            <div className="sign__text m24">
              Select a method to sign in or sign up.
            </div>
            <a onClick={signInGoogle} className="sign__button secondary">
              <img src="/images/sign/google.svg" alt="" />
              Continue with Google
            </a>
            <a
              onClick={() => setTab("email")}
              className="sign__button secondary"
            >
              <img src="/images/sign/mail.svg" alt="" />
              Continue with Email
            </a>
            <div className="sign__or">Or, login with...</div>
            <div className="sign__btns">
              <button
                type="button"
                style={{ cursor: "pointer" }}
                className="sign__btn"
                onClick={signInFacebook}
              >
                <img src="/images/sign/facebook.svg" alt="" />
              </button>
              <button
                type="button"
                style={{ cursor: "pointer" }}
                className="sign__btn"
                onClick={signInApple}
              >
                <img src="/images/sign/apple.svg" alt="" />
              </button>
            </div>

            <div className="sign__text sm" style={{ marginTop: 56 }}>
              By continuing you agree to Dossi, Inc.
              <a href="#">Terms & Conditions</a> and{" "}
              <a href="#">Privacy Policy</a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
