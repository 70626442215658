import { getAuth, signOut } from "firebase/auth";

import { setLoggedInUser } from "../../Services/FirebaseService";

const auth = getAuth();

export const handleLogoutUser = (removeCookie, setCurrentUser, setActive) => {
  removeCookie("username", "");
  removeCookie("uid", "");
  removeCookie("email", "");
  removeCookie("full_name", "");

  setLoggedInUser(null);
  signOut(auth);
  setActive?.(false);
  setCurrentUser?.(null);

  document.cookie = "";
};
