import React from "react";
import { getAuth } from "firebase/auth";

const AuthSuccessModal = ({ showSuccessModal, setShowSuccessModal }) => {
  const auth = getAuth();
  const currentUser = auth?.currentUser;

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setShowSuccessModal(false);
    }
  };

  return (
    <div className={"modalAuth active"} onClick={closeModal}>
      <div className="modalAuth__inner">
        <div
          className="modal__close"
          onClick={() => setShowSuccessModal(false)}
        >
          <img src="/images/sign/close.svg" alt="" />
        </div>
        <div className="modal__content">
          <div className="emoji">
            <h1>👋</h1>
          </div>
          <div className="modal__title">
            <h1>You're signed in!</h1>
          </div>
          <div className="modal__text">
            <p>
              Welcome back
              {currentUser.displayName && `, ${currentUser?.displayName}`}!
              You've successfully signed in as{" "}
              <span className="modal__email">{currentUser?.email}</span>
            </p>
          </div>
          <div className="modal__button">
            <button onClick={closeModal}>Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthSuccessModal;
