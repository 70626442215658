import { Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import React, { useState } from "react";
import { getDownloadURL, getMetadata, ref } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { saveAs } from "file-saver";
import { format } from "date-fns";

import { storage } from "../../../utils/firebase";
import {
  fetchProductFromID,
  fetchPurchasedItem,
  fetchUserInfo,
} from "../../../Services/FirebaseService";
import UserImage from "../../../Base/UserImage";
import UserBanner from "../../../Base/UserBanner";
import SignForm from "../../../Base/Sign/SignForm";

import ModalAuthUser from "../../../Base/ModalAuthUser";

var productResource = null;
var userInfoResource = null;
var productItemResource = null;

export default function ProductStatus() {
  const [cookies] = useCookies(["username", "uid", "email", "full_name"]);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  const [popup, setPopup] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [fileMetadata, setFileMetadata] = useState(null);
  const [downloadUrl, setFileDownloadURL] = useState(null);

  let { username, item_id } = useParams();

  if (productResource == null) {
    productResource = fetchPurchasedItem("product", item_id);
  }

  const purchasedProductItem = productResource.read();

  if (!purchasedProductItem.item) {
    window.open("/" + username, "_self");
  }

  if (userInfoResource == null) {
    userInfoResource = fetchUserInfo(purchasedProductItem.uid);
  }

  if (productItemResource == null) {
    productItemResource = fetchProductFromID(
      purchasedProductItem.uid,
      purchasedProductItem.item.productServiceID
    );
  }

  const user = userInfoResource.read();
  user.username = username;

  const product = purchasedProductItem.item;
  const productItem = productItemResource.read().product;

  const purchaseDate = new Date(product.id);

  const file = ref(storage, product.filePath);

  React.useEffect(() => {
    const getFileMetadata = async () => {
      try {
        const metadata = await getMetadata(file);
        const url = await getDownloadURL(file);

        setFileMetadata(metadata);
        setFileDownloadURL(url);
      } catch (error) {
        console.log(error);
      }
    };

    getFileMetadata();
  }, [file]);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const downloadFile = async () => {
    saveAs(downloadUrl, fileMetadata.name);
  };

  return (
    <>
      <div className="mainMore">
        <UserBanner user={user} hideMobile={true} />
        <Link
          to={"/" + username + "/products"}
          className="mainMore__back remove"
        >
          <img src="/images/icons/arrow-left.svg" alt="" />
        </Link>

        <div className="main__info">
          {currentUser ? (
            <div className="userImage" onClick={() => setPopup(!popup)}>
              <UserImage
                currentUser={currentUser}
                isModalOpen={popup}
                setIsModalOpen={setPopup}
              />
            </div>
          ) : (
            <div
              className="signinbutton"
              onClick={() => setLoginModalVisible(true)}
            >
              Sign in
            </div>
          )}
          {popup && <ModalAuthUser setIsModalOpen={setPopup} />}
        </div>
        {loginModalVisible && (
          <SignForm
            active={loginModalVisible}
            setActive={setLoginModalVisible}
            defaultEmail={signUpEmail}
          />
        )}
        <div className="auto__container">
          <div className="form__outer">
            <div className="form">
              <div className="form__inner">
                <div className="widget__inner">
                  <div className="widget__inner-request">
                    <div className="widget__inner-request-tick">
                      <img src="/images/icons/tick-green.svg" alt="" />
                    </div>
                    <div className="widget__inner-request-title">
                      Download ready!
                    </div>

                    <div
                      className="widget__inner-request-text"
                      style={{ color: "#5A6175" }}
                    >
                      Your digital product is ready to be downloaded!
                    </div>

                    {[product].map((product) => {
                      return (
                        <Link
                          to={"/" + username + "/products/pages/info"}
                          state={{
                            product: productItem,
                            user: user,
                            isFromStatus: true,
                            itemId: item_id,
                          }}
                        >
                          <div key={product.id} className="widget__inner-type">
                            <div className="widget__inner-type-content">
                              <div
                                className="widget__inner-general-category"
                                style={{
                                  backgroundColor: "#f2faf2",
                                  color: "#039700",
                                  borderRadius: 5,
                                  margin: 14,
                                  marginLeft: 0,
                                }}
                              >
                                PURCHASED
                              </div>
                              <div className="widget__inner-type-title">
                                {product.productTitle}
                              </div>
                              <div className="widget__inner-type-text">
                                {product.productDescription}
                              </div>
                              <div
                                className="widget__inner-type-footer"
                                style={{ justifyContent: "flex-start" }}
                              >
                                {user.headshot &&
                                  user.headshot.showHeadshot && (
                                    <div
                                      className="widget__inner-request-info-image"
                                      style={{
                                        height: 41,
                                        width: 41,
                                        borderRadius: 5,
                                      }}
                                    >
                                      <img
                                        src={user.headshot.headShotPath}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                <div>
                                  <div
                                    className="widget__inner-request-info-content-name"
                                    style={{ fontSize: 16, lineHeight: "18px" }}
                                  >
                                    {user.info.fullName}
                                  </div>
                                  <div
                                    className="widget__inner-request-info-content-prof"
                                    style={{
                                      marginBottom: 0,
                                      fontSize: 16,
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {user.info.title +
                                      ", " +
                                      user.info.workPlace}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}

                    <div
                      className="widget__inner-general-title"
                      style={{ marginTop: 36, marginBottom: 18 }}
                    >
                      Details
                    </div>

                    <div className="widget__inner-type">
                      <div className="widget__inner-type-content">
                        <div
                          className="widget__inner-type-text"
                          style={{ color: "#151924" }}
                        >
                          Purchased: {format(purchaseDate, "MMM dd, yyyy")}
                        </div>
                        <div
                          className="widget__inner-type-text"
                          style={{ color: "#151924" }}
                        >
                          Paid:{" "}
                          {product.rate && product.rate > 0
                            ? "$" + product.rate
                            : "FREE"}
                        </div>
                        <div
                          className="widget__inner-type-text"
                          style={{ color: "#0045FF", cursor: "pointer" }}
                        >
                          See receipt
                        </div>
                      </div>
                    </div>

                    <div
                      className="widget__inner-general-title"
                      style={{ marginTop: 36, marginBottom: 18 }}
                    >
                      File/Description
                    </div>

                    {fileMetadata && (
                      <div className="widget__inner-type">
                        <div className="widget__inner-type-content">
                          <div style={{ display: "flex" }}>
                            <div style={{ flexGrow: 1 }}>
                              <div
                                onClick={() => downloadFile()}
                                className="widget__inner-type-text"
                                style={{ color: "#0045FF", cursor: "pointer" }}
                              >
                                <span>
                                  <img
                                    src="/images/icons/file.svg"
                                    alt=""
                                    height={16}
                                    width={16}
                                    style={{ marginRight: 8, marginBottom: -2 }}
                                  />
                                </span>
                                {fileMetadata.name}
                              </div>
                              <div
                                className="widget__inner-type-text"
                                style={{ color: "#5A6175" }}
                              >
                                {fileMetadata.fullPath
                                  .split(".")
                                  .pop()
                                  .toUpperCase() +
                                  " • " +
                                  formatBytes(fileMetadata.size)}
                              </div>
                            </div>
                            <div>
                              <div
                                onClick={() => downloadFile()}
                                style={{
                                  paddiing: 8,
                                  borderRadius: 5,
                                  borderColor: "#F8F9FA",
                                  borderWidth: 2,
                                  borderStyle: "solid",
                                  width: 32,
                                  height: 32,
                                  display: "flex",
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src="/images/icons/download.svg"
                                  alt=""
                                  height={24}
                                  width={24}
                                  style={{ margin: "auto" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <button
                      className="sign__submit"
                      onClick={() => downloadFile()}
                      style={{
                        marginTop: 36,
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "100%",
                      }}
                    >
                      <span>
                        <img
                          src="/images/icons/download-button.svg"
                          alt=""
                          height={18}
                          width={18}
                          style={{ marginRight: 8, marginBottom: -2 }}
                        />
                      </span>
                      DOWNLOAD
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="widget__inner more"
              style={{ paddingTop: 12, paddingBottom: 12 }}
            >
              {!cookies.uid && (
                <div
                  className="widget__inner-request-footer"
                  style={{ justifyContent: "center" }}
                >
                  <div className="widget__inner-request-footer-title">
                    Create A Free Account
                  </div>
                  <div
                    className="widget__inner-request-footer-text"
                    style={{ maxWidth: "100%" }}
                  >
                    And manage this request on the go
                  </div>
                  <div className={"sign__input"}>
                    <input
                      onChange={(e) => setSignUpEmail(e.target.value)}
                      value={signUpEmail}
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                  <button
                    className="sign__submit"
                    onClick={() => setLoginModalVisible(true)}
                    style={{
                      marginTop: 36,
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: 174,
                    }}
                  >
                    SIGN UP FREE
                  </button>
                  <div
                    className="widget__inner-request-footer-text"
                    style={{
                      maxWidth: "100%",
                      color: "#5A6175",
                      fontSize: 14,
                      marginTop: 16,
                    }}
                  >
                    No Credit Card Required
                  </div>
                </div>
              )}

              {cookies.uid && (
                <div className="widget__inner-request-footer">
                  <div className="widget__inner-request-footer-title">
                    Download the Dossi App!
                  </div>
                  <div className="widget__inner-request-footer-text">
                    Manage your requests directly from your phone.
                  </div>
                  <div className="widget__inner-request-footer-row">
                    <a href="/">
                      <img src="/images/icons/appstore.svg" alt="" />
                    </a>
                    <a href="/">
                      <img src="/images/icons/google.svg" alt="" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="genBtn" style={{ marginTop: 0, marginBottom: 64 }}>
        Powered by{" "}
        <img
          className="dossifooterlogo"
          src="/images/icons/PoweredbyDossi.svg"
          alt="logo"
        />
      </div>
    </>
  );
}
