import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth";

import { store } from "../utils/firebase";
import { fetchBookings } from "../Services/FirebaseService";
import SignForm from "../Base/Sign/SignForm";

import UserImage from "./UserImage";
import ModalAuthUser from "./ModalAuthUser";

var bookingsResource = null;

export default function Header({
  user,
  setCurrentUser,
  currentUser,
  setIsAuthSuccessModal,
}) {
  const setChatOpen = store.useState("chatOpened")[1];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const auth = getAuth();

  const userActionColor = user.theme.actionColor || "#0038F0";

  useEffect(() => {
    setCurrentUser(auth.currentUser);
  }, [isModalOpen, isLoginModalOpen, auth.currentUser, setCurrentUser]);

  if (bookingsResource === null) {
    bookingsResource = fetchBookings(user.uid);
  }

  const bookingsInfo = bookingsResource.read();

  const bookings = bookingsInfo.bookings;
  bookings.sort((a, b) => a.rate - b.rate);

  var bookingRate = -1;
  var dmRate = -1;

  if (bookings.length > 0)
    bookingRate = bookings[0].rate === "" ? 0 : parseInt(bookings[0].rate);
  if (user.messageSettings.areMessagesEnabled) dmRate = 0;
  if (user.messageSettings.arePaidChatsEnabled)
    dmRate = user.messageSettings.rate;

  const areMessagesEnabled = user.messageSettings.areMessagesEnabled;
  const isPaidMessages = user.messageSettings.arePaidChatsEnabled;
  const isMeetingEnabled = bookings.length > 0;

  return (
    <>
      <header className="logowrap">
        <Link to="/home" className="dossilogo">
          <img src="/images/Dossilogo.svg" alt="Dossi" />
        </Link>
      </header>
      {isModalOpen && <ModalAuthUser setIsModalOpen={setIsModalOpen} />}
      {isLoginModalOpen && (
        <SignForm
          active={isLoginModalOpen}
          setActive={setIsLoginModalOpen}
          setIsAuthSuccessModal={setIsAuthSuccessModal}
        />
      )}
      <header className="signinarea">
        {currentUser ? (
          <div
            className="userImage"
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            <UserImage
              currentUser={currentUser}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </div>
        ) : (
          <div
            className="signinbutton"
            onClick={() => setIsLoginModalOpen(true)}
          >
            Sign in
          </div>
        )}
      </header>

      <footer className="header">
        <div className="header__inner">
          <nav className="nav">
            <div className="nav__inner">
              <div className="offerbox">
                <div
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#000824",
                    lineHeight: "15px",
                  }}
                >
                  {dmRate === -1 && bookingRate === -1 && "Unavailable for"}
                  {(dmRate > 0 || bookingRate > 0) && "Engage for"}
                  {dmRate === 0 && bookingRate === 0 && "Available for"}
                  {bookingRate >= 0 && dmRate === -1 && "Available for"}
                  {dmRate >= 0 &&
                    bookingRate === -1 &&
                    !isPaidMessages &&
                    "Available for"}
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 700,
                    fontSize: 14,
                    color: "#000824",
                    lineHeight: "17px",
                    marginTop: 4,
                  }}
                >
                  {dmRate === -1 && bookingRate === -1 && "DMs\n& Meetings"}
                  {dmRate === 0 && bookingRate === 0 && "DMs\n& Meetings"}
                  {bookingRate !== -1 &&
                    !areMessagesEnabled &&
                    !isPaidMessages &&
                    "Meetings"}
                  {dmRate > 0 && bookingRate === 0 && `$${dmRate}/DM`}
                  {bookingRate === -1 &&
                    areMessagesEnabled &&
                    dmRate === 0 &&
                    "DMs"}
                  {dmRate === -1 &&
                    areMessagesEnabled &&
                    bookingRate !== -1 &&
                    `$${dmRate}/ DM`}
                  {dmRate > 0 &&
                    areMessagesEnabled &&
                    bookingRate > 0 &&
                    `$${dmRate}/ DM`}
                  {dmRate <= 0 && bookingRate > 0 && `$${bookingRate}/Meeting`}
                  {isPaidMessages && bookingRate === -1 && `$${dmRate}/DM`}
                  {dmRate > 0 && bookingRate > 0 && `$${bookingRate}/Meeting`}
                </div>
                <div
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#000824",
                    lineHeight: "16px",
                    marginTop: 2,
                  }}
                >
                  {dmRate === -1 && bookingRate === -1 && ""}
                  {dmRate === 0 && bookingRate === 0 && ""}
                  {bookingRate !== -1 && !areMessagesEnabled && ""}
                  {bookingRate === 0 && !areMessagesEnabled && ""}
                  {bookingRate === -1 &&
                    areMessagesEnabled &&
                    dmRate === 0 &&
                    ""}

                  {dmRate !== -1 &&
                    areMessagesEnabled &&
                    bookingRate === -1 &&
                    ""}
                  {dmRate > 0 && bookingRate === 0 && `$${bookingRate}/Meeting`}

                  {areMessagesEnabled &&
                    !isPaidMessages &&
                    bookingRate > 0 &&
                    `$${dmRate}/DM`}
                </div>
              </div>

              <div className="nav__inner-btns">
                <Link
                  to="booking"
                  className={`button primary ${
                    isMeetingEnabled ? "" : "disabledLink"
                  }`}
                >
                  <img
                    src="/images/calendar.png"
                    alt=""
                    style={{ height: 24 }}
                  />
                  Book
                </Link>
                <button
                  type="button"
                  className="button primary"
                  onClick={() => setChatOpen(true)}
                  style={{
                    backgroundColor: userActionColor,
                    color: "#fff",
                    opacity: areMessagesEnabled ? "" : "0.3",
                  }}
                  disabled={!areMessagesEnabled}
                >
                  <img src="/images/dm.png" alt="" style={{ height: 24 }} />
                  DM
                </button>
              </div>
            </div>
          </nav>
        </div>
      </footer>
    </>
  );
}
