import React, { useRef, useEffect } from "react";

export default function ImageView(props) {
  const videoItem = useRef(null);
  useEffect(() => {}, [videoItem]);

  return (
    <div className={props.addClass}>
      <img
        style={{
          objectFit: "cover",
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
        src={props.url}
        alt=""
      ></img>
    </div>
  );
}
