import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { db } from "../utils/firebase";

export var loggedInUser = null;

export const setLoggedInUser = (user) => {
  loggedInUser = user;
};

export const fetchUser = (username) => {
  let status = "loading";
  var result;

  let suspender = getDoc(doc(db, "usernames", username))
    .then(async (data) => {
      status = "success";

      if (data.data()) result = data.data().uid;
      else result = null;
    })
    .catch((e) => {
      console.error(e);
      status = "error";
      result = e;
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchUserInfo = (uid) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([
    getDoc(doc(db, "users", uid, "info", "userInfo")),
    getDoc(doc(db, "users", uid, "headshots", "headshot")),
    getDoc(doc(db, "users", uid, "theme", "theme")),
    getDoc(doc(db, "users", uid, "messageSettings", "settings")),
  ])
    .then(([userData, headshotData, themeData, messageSettingsData]) => {
      status = "success";

      result = {
        uid: uid,
        info: userData.data(),
        headshot: headshotData.data(),
        theme: themeData.data(),
        messageSettings: messageSettingsData.data(),
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchOverviewInfo = (uid) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([
    getDocs(collection(db, "users", uid, "cards")),
    getDocs(collection(db, "users", uid, "socials")),
    getDocs(collection(db, "users", uid, "pageLinks")),
    getDoc(doc(db, "users", uid, "pitches", "pitch")),
  ])
    .then(([cardsData, socialsData, linksData, pitchData]) => {
      status = "success";

      let cards = cardsData.docs.map((d) => {
        var card = d.data();
        card.id = d.id;
        return card;
      });

      let socials = socialsData.docs.map((d) => {
        var social = d.data();
        social.id = d.id;
        return social;
      });

      let links = linksData.docs.map((d) => {
        var link = d.data();
        link.id = d.id;
        return link;
      });

      result = {
        uid: uid,
        cards: cards,
        socials: socials,
        links: links,
        pitch: pitchData.data(),
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchBookings = (uid) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([
    getDoc(doc(db, "users", uid, "bookingSettings", "settings")),
    getDocs(
      query(
        collection(db, "users", uid, "bookings"),
        where("state", "==", "active")
      )
    ),
  ])
    .then(([settings, data]) => {
      status = "success";

      let items = data.docs.map((d) => {
        var item = d.data();
        item.id = d.id;
        if (settings.exists && settings.data().calendarAvailability) {
          item.calendarAvailability = settings.data().calendarAvailability;
        }

        return item;
      });

      result = {
        uid: uid,
        bookings: items,
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchServices = (uid) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([
    getDocs(
      query(
        collection(db, "users", uid, "services"),
        where("state", "==", "ACTIVE")
      )
    ),
  ])
    .then(([data]) => {
      status = "success";

      let items = data.docs.map((d) => {
        var item = d.data();
        item.id = d.id;
        return item;
      });

      result = {
        uid: uid,
        services: items,
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchPurchasedItem = (type, itemId) => {
  let status = "loading";
  var result;
  var itemType;

  switch (type) {
    case "booking":
      itemType = "bookingEvents";
      break;

    case "product":
      itemType = "purchasedDigitalProducts";
      break;

    case "service":
      itemType = "purchasedServices";
      break;
    default:
      break;
  }

  let suspender = Promise.all([
    getDocs(
      query(collectionGroup(db, itemType), where("publicItemId", "==", itemId))
    ),
  ])
    .then(([data]) => {
      status = "success";

      if (data.docs.length > 0) {
        const item = data.docs[0].data();
        item.id = data.docs[0].id;

        result = {
          uid: data.docs[0].ref.parent.parent.id,
          item: item,
        };
      } else {
        result = {
          uid: null,
          item: null,
        };
      }
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchProductFromID = (uid, id) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([
    getDoc(doc(db, "users", uid, "digitalProducts", id)),
  ])
    .then(([data]) => {
      status = "success";

      var item = data.data();
      item.id = data.id;

      result = {
        uid: uid,
        product: item,
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchProducts = (uid) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([
    getDocs(
      query(
        collection(db, "users", uid, "digitalProducts"),
        where("state", "==", "ACTIVE")
      )
    ),
  ])
    .then(([data]) => {
      status = "success";

      let items = data.docs.map((d) => {
        var item = d.data();
        item.id = d.id;
        return item;
      });

      result = {
        uid: uid,
        products: items,
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchBookingEvents = (uid, bookingId) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([
    getDocs(
      query(
        collection(db, "users", uid, "bookingEvents"),
        where("bookingServiceID", "==", bookingId)
      )
    ),
  ])
    .then(([data]) => {
      status = "success";

      let items = data.docs
        .filter((b) => {
          return b.data().status !== "CANCELED";
        })
        .map((d) => {
          var item = d.data();
          item.id = d.id;
          return item;
        });

      result = {
        uid: uid,
        bookingEvents: items,
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchBookingFromID = (uid, id) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([getDoc(doc(db, "users", uid, "bookings", id))])
    .then(([data]) => {
      status = "success";

      var item = data.data();
      item.id = data.id;

      result = {
        uid: uid,
        booking: item,
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export const fetchServiceFromID = (uid, id) => {
  let status = "loading";
  var result;

  let suspender = Promise.all([getDoc(doc(db, "users", uid, "services", id))])
    .then(([data]) => {
      status = "success";

      var item = data.data();
      item.id = data.id;

      result = {
        uid: uid,
        service: item,
      };
    })
    .catch((err) => {
      status = "error";
      console.log(err);
    });

  return {
    read() {
      if (status === "loading") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};
