import { useCookies } from "react-cookie";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getAuth } from "firebase/auth";

import { handleLogoutUser } from "../utils/utils";

import UserImage from "./UserImage";

const ModalAuthUser = ({ setIsModalOpen }) => {
  const [, removeCookie] = useCookies([
    "username",
    "uid",
    "email",
    "full_name",
  ]);

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const modalRef = useRef(null);
  const [modalHeight, setModalHeight] = useState(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    },
    [setIsModalOpen]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const timeoutCalc = setTimeout(() => {
      const modalHeight = modalRef.current.offsetHeight;
      const windowHeight = window.innerHeight;

      if (modalHeight > windowHeight - 150) {
        setModalHeight(windowHeight - 150);
      } else {
        setModalHeight(null);
      }
    }, 100);

    return () => clearTimeout(timeoutCalc);
  }, [setIsModalOpen]);

  const styleModal = modalHeight
    ? { height: modalHeight, overflowY: "scroll" }
    : null;

  return (
    <div className="main__info-content" ref={modalRef} style={styleModal}>
      <div className="main__info-content-header">
        <div className="image">
          {currentUser && <UserImage currentUser={currentUser} />}
        </div>
        <div className="info">
          {currentUser.displayName}
          <span>{currentUser.email}</span>
        </div>
      </div>
      <hr />
      <div className="main__info-content-account">
        My account <span>Notifications, Password etc</span>
      </div>
      <hr />
      <div className="main__info-content-logo">
        <img src="/images/icons/sign.svg" alt="" />
      </div>
      <div className="main__info-content-qr">
        <span>Scan the QR code to download the app</span>
        <img src="/images/qr.png" alt="" />
      </div>
      <div className="main__info-content-btns">
        <button>
          <img src="/images/googlePlay.svg" alt="" />
        </button>
        <button>
          <img src="/images/appStore.svg" alt="" />
        </button>
      </div>
      <div className="main__info-content-footer">
        <button
          onClick={() =>
            handleLogoutUser(removeCookie, undefined, setIsModalOpen)
          }
          className="logout"
        >
          Log Out 👋
        </button>
      </div>
    </div>
  );
};

export default ModalAuthUser;
