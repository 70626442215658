/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useEffect } from "react";

const AutoGrowTextArea = forwardRef(({ setTextareaHeight, ...props }, ref) => {
  const getPropertyValue = (it) => {
    return Number.parseFloat(
      window.getComputedStyle(ref.current).getPropertyValue(it)
    );
  };

  const resize = () => {
    const borderTop = getPropertyValue("border-top");
    const borderBottom = getPropertyValue("border-bottom");

    if (ref?.current) {
      ref.current.style.height = "auto";

      const height = ref.current.scrollHeight + borderTop + borderBottom;

      ref.current.style.height = `${height}px`;
      setTextareaHeight(height);
    }
  };

  const delayedResize = () => {
    window.setTimeout(resize, 0);
  };

  useEffect(() => {
    resize();
  });

  return (
    <textarea
      ref={ref}
      onChange={resize}
      onCut={delayedResize}
      onPaste={delayedResize}
      onDrop={delayedResize}
      rows={1}
      {...props}
    />
  );
});

export default AutoGrowTextArea;
