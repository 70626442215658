import { useMessageInputContext } from "stream-chat-react";

const CustomMessageButton = ({ textareaHeight }) => {
  const { handleSubmit } = useMessageInputContext();

  return (
    <div
      onClick={handleSubmit}
      className="message__button"
      style={{ marginBottom: textareaHeight > 19 ? "0" : "10px" }}
    >
      <svg
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="20.0019" cy="20.5" rx="19.998" ry="20" fill="#0038F0" />
        <path
          d="M19.9848 28V14M19.9848 14L12.9915 21M19.9848 14L26.9782 21"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default CustomMessageButton;
