import React from "react";

export default function Loading({ color }) {
  return (
    <div className="widgetLoading">
      <div className="lds-ellipsis">
        <div style={{ background: color }}></div>
        <div style={{ background: color }}></div>
        <div style={{ background: color }}></div>
        <div style={{ background: color }}></div>
      </div>
    </div>
  );
}
