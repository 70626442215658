import {
  EmojiIconLarge,
  EmojiPicker,
  Tooltip,
  useMessageInputContext,
  useTranslationContext,
  UploadsPreview,
} from "stream-chat-react";
import { FileUploadButton, ImageDropzone } from "react-file-utils";

import { useRef, useState } from "react";

import CustomMessageUploadIcon from "./CustomMessageUploadIcon";
import CustomMessageButton from "./CustomMessageButton";
import AutoGrowTextArea from "./AutoGrowTextArea";

const CustomMessageInput = ({ messagesEndRef, sidebarRef }) => {
  const textareaRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState(19);
  const [isFocused, setIsFocused] = useState(false);

  const { t } = useTranslationContext();
  const {
    closeEmojiPicker,
    emojiPickerIsOpen,
    openEmojiPicker,
    text,
    setText,
    handleSubmit,
    uploadNewFiles,
    isUploadEnabled,
    imageUploads,
  } = useMessageInputContext();

  const handleInputChange = (e) => setText(e.target.value);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const handleClick = () => textareaRef?.current?.focus();

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleSubmit(e, text);
      if (sidebarRef.current) {
        sidebarRef.current.scroll({
          top: messagesEndRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  };

  const uploadValue = Object.values(imageUploads)[0];
  const isFinishUpload = uploadValue?.state === "finished";

  return (
    <div
      className="message__input__content"
      style={{ alignItems: textareaHeight > 19 ? "flex-end" : null }}
    >
      <div
        className={`str-chat__input-flat--textarea-wrapper ${
          text ? "has_text" : ""
        }`}
        style={{ alignItems: textareaHeight > 19 ? "flex-end" : "center" }}
      >
        {isUploadEnabled && <UploadsPreview />}
        <FileUploadButton handleFiles={uploadNewFiles}>
          <CustomMessageUploadIcon />
        </FileUploadButton>
        <ImageDropzone handleFiles={uploadNewFiles}>
          <div
            className={`message-textarea-container${isFocused ? " focused" : ""}`}
            onClick={handleClick}
          >
              <AutoGrowTextArea
                ref={textareaRef}
                onChange={handleInputChange}
                onPaste={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                setTextareaHeight={setTextareaHeight}
                value={text}
                rows={1}
                placeholder="Message"
                className="message-textarea"
              />
          </div>
        </ImageDropzone>
        <div className="str-chat__emojiselect-wrapper ">
          <Tooltip>
            {emojiPickerIsOpen
              ? t("Close emoji picker")
              : t("Open emoji picker")}
          </Tooltip>
          <span
            className="str-chat__input-flat-emojiselect"
            onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
            role="button"
            tabIndex={0}
          >
            <EmojiIconLarge />
          </span>
        </div>
        <EmojiPicker />
      </div>
      {(text || isFinishUpload) && (
        <CustomMessageButton textareaHeight={textareaHeight} />
      )}
    </div>
  );
};

export default CustomMessageInput;
