import React, { useRef, useState, useEffect } from "react";
import MuxVideo from "@mux-elements/mux-video-react";

export default function Video(props) {
  const videoItem = useRef(null);
  const [paused, setPaused] = useState(true);
  const [fullscreen, setFullScreen] = useState(false);
  useEffect(() => {}, [videoItem]);

  document.onfullscreenchange = (event) => {
    console.log(document.fullscreenElement);
    if (!document.fullscreenElement) {
      setFullScreen(false);
      videoItem.current.pause();
    }
  };

  const onFullScreenClick = () => {
    if (videoItem.current.requestFullscreen) {
      videoItem.current.requestFullscreen();
    } else if (videoItem.current.msRequestFullscreen) {
      videoItem.current.msRequestFullscreen();
    } else if (videoItem.current.mozRequestFullScreen) {
      videoItem.current.mozRequestFullScreen();
    } else if (videoItem.current.webkitRequestFullscreen) {
      videoItem.current.webkitRequestFullscreen();
    }

    setFullScreen(fullscreen);
    videoItem.current.play();
  };

  return (
    <div
      className={props.addClass}
      onClick={() => {
        if (videoItem.current.paused) {
          videoItem.current.play();
        } else {
          videoItem.current.pause();
        }
        setPaused(videoItem.current.paused);
      }}
    >
      {videoItem.current &&
        !videoItem.current.paused &&
        props.showFullscreen &&
        props.isFullscreenLeft && (
          <img
            onClick={onFullScreenClick}
            style={{
              marginLeft: "auto",
              zIndex: 10,
              marginTop: "auto",
              padding: 18,
              cursor: "pointer",
            }}
            className="enlarge"
            width={28}
            height={28}
            src={"/images/icons/enlarge.svg"}
            alt=""
          />
        )}

      {videoItem.current &&
        !videoItem.current.paused &&
        props.showFullscreen &&
        !props.isFullscreenLeft &&
        !props.isFullscreenBottom && (
          <img
            onClick={onFullScreenClick}
            style={{
              marginLeft: "auto",
              right: 0,
              zIndex: 10,
              marginTop: "auto",
              padding: 18,
              cursor: "pointer",
              position: "absolute",
            }}
            width={28}
            height={28}
            src={"/images/icons/enlarge.svg"}
            alt=""
          />
        )}

      {videoItem.current &&
        !videoItem.current.paused &&
        props.showFullscreen &&
        !props.isFullscreenLeft &&
        props.isFullscreenBottom && (
          <img
            onClick={onFullScreenClick}
            style={{
              marginRight: "auto",
              right: 0,
              zIndex: 10,
              bottom: 0,
              marginBottom: "auto",
              padding: 18,
              cursor: "pointer",
              position: "absolute",
            }}
            width={28}
            height={28}
            src={"/images/icons/enlarge.svg"}
            alt=""
          />
        )}

      <button className={`videoPlay ${!paused ? "paused" : ""}`}>
        <span></span>
        {props.buttonText ? <small>{props.buttonText}</small> : ""}
      </button>

      <MuxVideo
        ref={videoItem}
        style={{
          height: "100%",
          maxWidth: "100%",
          objectFit: fullscreen ? "contain" : "cover",
        }}
        src={props.url}
        poster={props.poster}
      />
    </div>
  );
}
