import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import React, { Suspense, useEffect, useState } from "react";

import { getAuth } from "firebase/auth";

import { fetchUser, fetchUserInfo } from "./Services/FirebaseService";
import ServiceStatus from "./Pages/Services/ServiceStatus/ServiceStatus";
import ProductStatus from "./Pages/Products/ProductStatus/ProductStatus";
import BookingStatus from "./Pages/Bookings/BookingStatus/BookingStatus";
import Loading from "./Loading";
import Main from "./Base/Main";
import Header from "./Base/Header";
import ChatView from "./Base/ChatView";
import AuthSuccessModal from "./Base/AuthSuccessModal";

function App() {
  const auth = getAuth();

  const [, setCookie] = useCookies(["username", "uid", "email", "full_name"]);

  const [isAuthSuccessModal, setIsAuthSuccessModal] = useState(false);

  const About = React.lazy(() => import("./Pages/Overview/About/About"));
  const BookingRoutes = React.lazy(() =>
    import("./Pages/Bookings/BookingRoutes")
  );
  const ProductsRoutes = React.lazy(() =>
    import("./Pages/Products/ProductsRoutes")
  );
  const ServicesRoutes = React.lazy(() =>
    import("./Pages/Services/ServicesRoutes")
  );
  const SignIn = React.lazy(() => import("./Pages/SignIn/SignIn"));

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCookie("username", user.username || user.displayName, { path: "/" });
        setCookie("email", user.email, { path: "/" });
        setCookie("uid", user.uid, { path: "/" });
        setCookie("full_name", user.displayName, { path: "/" });
      }
    });
    return unsubscribe;
  }, [auth, setCookie]);

  return (
    <BrowserRouter>
      {isAuthSuccessModal && (
        <AuthSuccessModal
          showSuccessModal={isAuthSuccessModal}
          setShowSuccessModal={setIsAuthSuccessModal}
        />
      )}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/:username/about/*" element={<About />} />
          <Route
            path="/:username/booking/pages/*"
            element={
              <BookingRoutes setIsAuthSuccessModal={setIsAuthSuccessModal} />
            }
          />
          <Route
            path="/:username/products/pages/*"
            element={<ProductsRoutes />}
          />
          <Route
            path="/:username/services/pages/*"
            element={<ServicesRoutes />}
          />
          <Route path="/:username/sign-in/*" element={<SignIn />} />
          <Route
            path="/:username/booking/status/:item_id"
            element={<BookingStatus />}
          />
          <Route
            path="/:username/product/view/:item_id"
            element={<ProductStatus />}
          />
          <Route
            path="/:username/service/view/:item_id"
            element={<ServiceStatus />}
          />
          <Route path="/:username/*" element={<AppInner />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

var userResource = null;
var userInfoResource = null;

export function AppInner() {
  const [, setCookie] = useCookies(["username", "uid", "email", "full_name"]);

  let { username } = useParams();
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(auth.currentUser || null);
  const [isAuthSuccessModal, setIsAuthSuccessModal] = useState(false);

  if (userResource === null) {
    userResource = fetchUser(username);
  }

  useEffect(() => {
    if (currentUser) {
      setCookie("username", currentUser.username || currentUser.displayName, {
        path: "/",
      });
      setCookie("email", currentUser.email, { path: "/" });
      setCookie("uid", currentUser.uid, { path: "/" });
      setCookie("full_name", currentUser.displayName, { path: "/" });
    }
  }, [currentUser, setCookie]);

  const user = userResource.read();

  if (userInfoResource === null) {
    userInfoResource = fetchUserInfo(user);
  }

  const userInfo = userInfoResource.read();
  userInfo.username = username;

  const Overview = React.lazy(() => import("./Pages/Overview/Overview"));
  const Booking = React.lazy(() => import("./Pages/Bookings/Booking"));
  const Services = React.lazy(() => import("./Pages/Services/Services"));
  const Products = React.lazy(() => import("./Pages/Products/Products"));

  const pathname = window.location.pathname;

  return (
    <>
      {!pathname.endsWith("booking") && (
        <Header
          user={userInfo}
          setCurrentUser={setCurrentUser}
          currentUser={currentUser}
          setIsAuthSuccessModal={setIsAuthSuccessModal}
        />
      )}

      {isAuthSuccessModal && (
        <AuthSuccessModal
          showSuccessModal={isAuthSuccessModal}
          setShowSuccessModal={setIsAuthSuccessModal}
        />
      )}

      {!pathname.endsWith("booking") && (
        <ChatView user={userInfo} currentUser={currentUser} />
      )}

      <Main user={userInfo}>
        <Suspense fallback={<Loading color={userInfo.theme.actionColor} />}>
          <Routes>
            <Route
              path={"*"}
              element={
                <Overview
                  user={userInfo}
                  setCurrentUser={setCurrentUser}
                  currentUser={currentUser}
                  setIsAuthSuccessModal={setIsAuthSuccessModal}
                  isAuthSuccessModal={isAuthSuccessModal}
                />
              }
            />
            <Route path="/booking" element={<Booking user={userInfo} />} />
            <Route path="/services" element={<Services user={userInfo} />} />
            <Route path="/products" element={<Products user={userInfo} />} />
          </Routes>
        </Suspense>
      </Main>
    </>
  );
}

export default App;
