/* eslint-disable jsx-a11y/anchor-is-valid */
import { v4 } from "uuid";
import {
  Chat,
  Channel,
  MessageList,
  MessageInput,
  Window,
  Avatar,
  LoadingIndicator,
} from "stream-chat-react";
import { StreamChat } from "stream-chat";
import { Link } from "react-router-dom";
import Drawer from "react-modern-drawer";
import React, { Fragment, useEffect, useState } from "react";
import "react-modern-drawer/dist/index.css";
import "stream-chat-react/dist/css/index.css";
import { Magic, RPCError, RPCErrorCode } from "magic-sdk";
import { httpsCallable } from "firebase/functions";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import {
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCustomToken,
} from "firebase/auth";
import { DeviceUUID } from "device-uuid";
import { loadStripe } from "@stripe/stripe-js";

import { Elements } from "@stripe/react-stripe-js";

import { addUserToDB } from "../utils/utils";
import { auth, db, functions, store } from "../utils/firebase";

import { setLoggedInUser } from "../Services/FirebaseService";

import usePrevValue from "../hooks/usePrevValue";

import CustomMessageInput from "./CustomMessageInput";
import CustomMessageButton from "./CustomMessageButton";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const magic = new Magic(process.env.REACT_APP_MAGIC_API_KEY);
const provider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export default function ChatView({ user, currentUser }) {
  const [chatOpened, setChatOpen] = store.useState("chatOpened");

  const messagesEndRef = React.useRef(null);
  const sidebarRef = React.useRef(null);
  const [chatInitiated, setChatInitiated] = React.useState(false);
  const [chatClient, setChatClient] = React.useState(null);
  const [channel, setMyChannel] = React.useState(null);
  const [searchMessages, setSearchMessages] = React.useState([]);
  const [messageSearchTerm, setSearchTerm] = React.useState(null);
  const [paymentModalActive, setPaymentModalActive] = React.useState(false);
  const [formModalActive, setFormModalActive] = React.useState(false);
  const [chatLoaded, setChatLoaded] = useState(false);
  const [searchingMessages, setSearchingMessages] = useState(false);
  const [requestProcessing, setRequestProcessing] = useState(false);
  const [generatedDeviceId, setGeneratedDeviceId] = useState("");
  const [newMessageCount, setNewMessageCount] = useState(0);
  const [clientSecret, setClientSecret] = useState(null);
  const [fullNameFormVisible, setFullNameFormVisible] = useState(false);
  const [emailFormVisible, setEmailFormVisible] = useState(true);
  const [isCancelRequest, setIsCancelRequest] = useState(false);

  const userDescription =
    user.info?.title && user.info?.workPlace
      ? `${user.info.title.trim()}, ${user.info.workPlace.trim()}`
      : `${user.info?.title?.trim()}${user.info?.workPlace?.trim()}`;

  const chatRequestText = user.messageSettings.arePaidChatsEnabled
    ? `You will be charged ${user?.messageSettings?.rate || 0}$ when ${
        user.info.fullName
      } responds with 15 words or more. If no response is made within 48 hours, your request will be canceled.`
    : null;

  const prevCurrentUser = usePrevValue(currentUser);

  React.useEffect(() => {
    const initChat = async () => {
      let client = StreamChat.getInstance("jnpezjqhrzpt", { timeout: 15000 });

      if (
        client.user &&
        chatLoaded &&
        chatClient &&
        channel &&
        prevCurrentUser?.uid === currentUser?.uid
      )
        return;

      if (currentUser?.uid === user.uid) return;

      const generatedUID = new DeviceUUID().get();
      setGeneratedDeviceId(generatedDeviceId);

      const userId = currentUser?.uid ? currentUser.uid : generatedUID;

      const streamTokenCallable = httpsCallable(
        functions,
        "generateStreamUserToken"
      );

      const res = (await streamTokenCallable({ uid: userId })).data;

      if (prevCurrentUser?.uid !== currentUser?.uid) {
        setChatLoaded(false);
        setChatClient(null);
        setMyChannel(null);
        await client.disconnectUser();
      }

      await client.connectUser({ id: userId }, res.token);

      const cid = currentUser?.uid
        ? currentUser?.uid + "-" + user.uid
        : generatedUID.replace(/-/g, "") + "-" + user.uid;

      const ch = client.channel("messaging", cid, {
        members: [userId, user.uid, "Dossi"],
        name: user.info.fullName,
      });

      await ch.create("");
      await ch.watch({ presence: true });

      ch.on("notification.message_new", async (e) => {
        if (e.user_id !== userId) {
          document.title = `(${newMessageCount + 1}) Dossi App`;
          const audio = new Audio("assets/message_notification.mp3");
          audio.play();

          setNewMessageCount(newMessageCount + 1);
        }
      });

      ch.on("notification.mark_read", async (e) => {
        document.title = "Dossi App";
        setNewMessageCount(0);
      });

      const exists =
        (
          await client.queryChannels(
            { cid: "messaging:" + cid },
            {},
            {
              watch: false,
              state: false,
            }
          )
        ).length !== 0;

      if (!exists) {
        const sendInitialMessagesCallable = httpsCallable(
          functions,
          "sendInitialStreamMessages"
        );
        await sendInitialMessagesCallable({
          cid: ch.cid,
          uid: user.uid,
          welcomeMessage: user.messageSettings.welcomeMessage,
          name: user.info.fullName,
          rate: user.messageSettings.rate,
          paidChatsEnabled: user.messageSettings.arePaidChatsEnabled,
        }).catch((e) => console.error(e));
      }

      const members = await ch.queryMembers({});

      if (
        members.members.findIndex((m) => m.user_id === generatedUID) !== -1 &&
        currentUser?.uid
      ) {
        await ch.addMembers([currentUser?.uid]);
        await ch.removeMembers([generatedUID]);
        await ch.updatePartial({
          set: { cid: cid, clientUID: currentUser?.uid },
        });
      }

      setMyChannel(ch);
      setChatClient(client);
      setChatLoaded(true);

      if (ch.data.status) setChatInitiated(true);
    };

    initChat();
  }, [
    channel,
    chatClient,
    currentUser?.uid,
    generatedDeviceId,
    newMessageCount,
    user,
    chatLoaded,
    prevCurrentUser?.uid,
  ]);

  useEffect(() => {
    const searchMessage = async () => {
      if (messageSearchTerm && messageSearchTerm !== "") {
        const channelFilters = { cid: channel.cid };
        const messageFilters = { text: { $autocomplete: messageSearchTerm } };

        const result = await chatClient.search(channelFilters, messageFilters);

        setSearchMessages(
          result.results.map((r) => {
            r.message.key = v4();
            return r.message;
          })
        );

        setSearchingMessages(false);
      } else {
        setSearchMessages(null);
      }
    };

    const searchConversation = setTimeout(() => {
      if (channel && messageSearchTerm) {
        setSearchingMessages(true);
        searchMessage();
      }
    }, 500);

    return () => {
      clearTimeout(searchConversation);
    };
  }, [channel, chatClient, messageSearchTerm]);

  React.useEffect(() => {
    if (chatOpened) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatOpened]);

  const CustomAvatar = (props) => {
    return props.user.id === user.uid ||
      props.user.id.toLowerCase() === "dossi" ? (
      <Avatar
        image={
          user.headshot && props.user.id === user.uid
            ? user.headshot.headShotPath
            : "/images/dossi-logo.png"
        }
        shape="circle"
        size={28}
      />
    ) : (
      <Avatar size={0} />
    );
  };

  const [form, setForm] = useState({
    fullName: "",
    email: "",
  });

  const [validation, setValidation] = useState({
    fullName: true,
    email: true,
  });

  const sendMagicLink = async () => {
    try {
      const didToken = await magic.auth.loginWithMagicLink({
        email: form.email,
        showUI: true,
      });

      const fbAuth = httpsCallable(functions, "auth");
      let res = (await fbAuth({ didToken })).data;

      await signInWithCustomToken(auth, res.token)
        .then((result) => {
          const user = result.user;
          loginUser(user);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);

      if (error instanceof RPCError) {
        switch (error.code) {
          case RPCErrorCode.UserRequestEditEmail:
            setForm({
              fullName: "",
              email: "",
            });

            setValidation({
              fullName: true,
              email: true,
            });

            setFullNameFormVisible(false);
            setEmailFormVisible(true);
            break;

          default:
            break;
        }
      }
    }
  };

  const onClick = () => {
    const newState = {};
    let isValid = true;

    if (emailFormVisible) {
      if (form["email"] === "") {
        newState["email"] = false;
        isValid = false;
      }

      if (!validateEmail(form["email"])) {
        newState["email"] = false;
        isValid = false;
      } else {
        newState["email"] = true;
        newState["fullName"] = true;
      }
    } else {
      if (form["fullName"] === "") {
        newState["fullName"] = false;
        isValid = false;
      }

      newState["fullName"] = true;
    }

    setValidation(newState);

    if (isValid) {
      if (fullNameFormVisible) {
        sendMagicLink();
      } else {
        setFullNameFormVisible(true);
        setEmailFormVisible(false);
      }
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const processStripe = async () => {
    const itemCallable = httpsCallable(functions, "createStripePaymentIntent");
    let stripeItem = (
      await itemCallable({
        amount: 0.5,
      })
    ).data;

    setClientSecret(stripeItem);
  };

  const onPaymentSuccess = async (paymentIntentID) => {
    const itemCallable = httpsCallable(functions, "requestDM");

    setFormModalActive(false);
    setPaymentModalActive(false);
    setRequestProcessing(true);

    await itemCallable({
      rate: user.messageSettings.rate,
      paymentIntentID: paymentIntentID,
      cid: channel.cid,
      name: user.info.fullName,
      business_uid: user.uid,
      client_uid: currentUser?.uid ? currentUser?.uid : generatedDeviceId,
    });

    setMyChannel(channel);
    setRequestProcessing(false);

    setChatInitiated(true);
  };

  const initiateChat = () => {
    if (!currentUser) return setFormModalActive(true);

    if (user.messageSettings.arePaidChatsEnabled) setPaymentModalActive(true);
    else onPaymentSuccess(null);
  };

  const cancelChatRequest = async () => {
    await channel.updatePartial({ set: { status: "CANCELED" } });

    setMyChannel(channel);
    setRequestProcessing(false);
    setChatInitiated(false);

    const itemCallable = httpsCallable(functions, "sendDMCancelMessage");
    itemCallable({
      cid: channel.cid,
      name: user.info.fullName,
    });
    setIsCancelRequest(false);
  };

  const updateState = (data) => {
    setForm({ ...form, ...data });
  };

  const color =
    user.theme && user.theme.secondaryColor
      ? user.theme.secondaryColor
      : "#0038F0";

  const signInApple = () => {
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const user = result.user;

        addUserToDB(user);
        loginUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const signInGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const user = result.user;

        addUserToDB(user);
        loginUser(user);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loginUser = async (mUser) => {
    const userDocs = (
      await getDocs(
        query(collection(db, "usernames"), where("uid", "==", mUser.uid))
      )
    ).docs;

    if (userDocs.length !== 0) {
      const username = userDocs[0].id;
      const userInfo = await getDoc(
        doc(db, "users", mUser.uid, "info", "userInfo")
      );

      mUser.username = username;
      mUser.userInfo = userInfo;
    } else {
      mUser.username = mUser.displayName;
    }

    setLoggedInUser(mUser);

    setFormModalActive(false);
    if (user.messageSettings.arePaidChatsEnabled) setPaymentModalActive(true);
    else onPaymentSuccess(null);
  };

  const showLoginBtn =
    !currentUser ||
    (((!chatInitiated && !channel?.data?.status) ||
      channel?.data?.status === "CANCELED") &&
      !requestProcessing);

  if (!chatLoaded) {
    return (
      <Drawer open={chatOpened} direction="right" className="chat_drawer">
        <div className="stripe_spinner" style={{ height: "100%" }}>
          <LoadingIndicator size={32} color="#5469d4" />
        </div>
      </Drawer>
    );
  }

  return (
    <Fragment>
      {formModalActive && (
        <div
          id="paymentModal"
          className={`widget__payment ${formModalActive ? "active" : ""}`}
          onClick={(e) => {
            if (e.target.id === "paymentModal") setFormModalActive(false);
          }}
        >
          <div className="widget__payment-inner">
            <div
              className="widget__payment-inner-close"
              onClick={() => {
                setFormModalActive(false);
              }}
            >
              <img src="/images/icons/close.svg" alt="" />
            </div>
            {user.headshot && user.headshot.showHeadshot && (
              <div className="widget__payment-inner-avatar">
                <img src={user.headshot.headShotPath} alt="" />
              </div>
            )}
            {(!user.headshot || !user.headshot.showHeadshot) && (
              <div className="widget__inner-general-avatar">
                <img
                  src="/images/initialsBackground.png"
                  style={{ objectFit: "fill", border: 0 }}
                  alt=""
                />
                <div
                  className="widget__inner-info-name"
                  style={{
                    position: "absolute",
                    fontWeight: "normal",
                    fontSize: 24,
                    color: "#545963",
                  }}
                >
                  MM
                </div>
              </div>
            )}
            <div className="widget__header" style={{ display: "block" }}>
              <div
                className="widget__header-title"
                style={{
                  fontSize: 24,
                  textAlign: "center",
                  letterSpacing: "-2%",
                }}
              >
                DM {user.info.fullName}
              </div>
              <div
                className="sign__or"
                style={{ marginTop: 8, marginBottom: 40 }}
              >
                Enter your details to continue/sign in
              </div>
            </div>
            {fullNameFormVisible && (
              <div
                className={`form__input ${
                  validation.fullName ? "" : "invalid"
                }`}
              >
                <label htmlFor="">
                  Full name <span>*</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => updateState({ fullName: e.target.value })}
                  value={form.fullName}
                  placeholder="Full name"
                />
                <div className="error">Can’t leave field blank</div>
              </div>
            )}
            {emailFormVisible && (
              <div
                className={`form__input ${validation.email ? "" : "invalid"}`}
              >
                <label htmlFor="">
                  Email address <span>*</span>
                </label>
                <input
                  type="text"
                  onChange={(e) => updateState({ email: e.target.value })}
                  value={form.email}
                  placeholder="Email"
                />
                <div className="error">Please enter a valid email address</div>
              </div>
            )}
            <div className="form__submit">
              <button
                type="button"
                onClick={onClick}
                style={{ backgroundColor: color }}
              >
                CONTINUE
              </button>
            </div>
            <div className="sign">
              <div
                className="sign__or"
                style={{ marginTop: 40, color: "#5A6175", fontSize: 14 }}
              >
                Or, continue with...
              </div>
              <div className="sign__btns">
                <button
                  type="button"
                  style={{ cursor: "pointer" }}
                  className="sign__btn"
                  onClick={signInGoogle}
                >
                  <img src="/images/sign/google.svg" alt="" />
                </button>
                <button
                  type="button"
                  style={{ cursor: "pointer" }}
                  className="sign__btn"
                  onClick={signInApple}
                >
                  <img src="/images/sign/apple.svg" alt="" />
                </button>
              </div>
            </div>
            <div
              className="sign__text sm"
              style={{ marginTop: 24, marginBottom: -16 }}
            >
              By continuing you agree to Dossi, Inc.
              <a href="#" style={{ display: "inline" }}>
                Terms & Conditions
              </a>{" "}
              and{" "}
              <a href="#" style={{ display: "inline" }}>
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      )}

      {paymentModalActive && (
        <div
          id="paymentModal"
          className={`widget__payment ${paymentModalActive ? "active" : ""}`}
          onClick={(e) => {
            if (e.target.id === "paymentModal") setPaymentModalActive(false);
          }}
        >
          <div className="widget__payment-inner">
            <div
              className="widget__payment-inner-close"
              onClick={() => {
                setPaymentModalActive(false);
              }}
            >
              <img src="/images/icons/close.svg" alt="" />
            </div>

            {user.headshot && user.headshot.showHeadshot && (
              <div className="widget__payment-inner-avatar">
                <img src={user.headshot.headShotPath} alt="" />
              </div>
            )}
            <div className="widget__header">
              <div
                className="widget__header-title"
                style={{ fontSize: 24, letterSpacing: "-2%" }}
              >
                DM {user.info.fullName}
              </div>
              <div
                className="sign__or"
                style={{ marginTop: 8, marginBottom: 40 }}
              >
                Select a payment option
              </div>
            </div>

            <div className="widget__payment-inner-title">PAY WITH</div>

            <a onClick={processStripe} className="widget__payment-inner-card">
              DEBIT OR CREDIT CARD
            </a>

            <Link to="/sign-in/request" className="widget__payment-inner-pal">
              <img src="/images/paypal.png" alt="" />
            </Link>

            {currentUser?.email && (
              <div
                className="sign__or"
                style={{
                  marginTop: 20,
                  marginBottom: 4,
                  fontSize: 14,
                  color: "#5A6175",
                }}
              >
                Logged in as:
              </div>
            )}
            {currentUser?.email && (
              <div
                className="sign__or"
                style={{ marginTop: 4, color: "#5A6175" }}
              >
                {currentUser.email}
              </div>
            )}
          </div>
        </div>
      )}

      {clientSecret && (
        <div className={`stripe_payment_modal ${clientSecret ? "active" : ""}`}>
          <div
            className="stripe_payment_modal-inner-close"
            onClick={() => {
              setClientSecret(null);
            }}
          />
          <div className="stripe_payment_modal-inner">
            <Elements
              options={{
                appearance: {
                  theme: "stripe",
                },
                clientSecret: clientSecret,
              }}
              stripe={stripePromise}
            >
              <CheckoutForm
                customerEmail={
                  currentUser?.email ? currentUser?.email : form.email
                }
                onPaymentSuccess={onPaymentSuccess}
                onCloseModal={() => setClientSecret(null)}
                bottomMessage="We'll send a test charge of $0.5 to verify your card. We will immediately refund you once we've verified your card."
              />
            </Elements>
          </div>
        </div>
      )}

      <Drawer open={chatOpened} direction="right" className="chat_drawer">
        {!chatLoaded && (
          <div className="stripe_spinner" style={{ height: "100%" }}>
            <LoadingIndicator size={32} color="#5469d4" />
          </div>
        )}

        {chatLoaded && (
          <div style={{ width: "100%" }}>
            {chatClient && (
              <Chat client={chatClient} theme="messaging light">
                <Channel
                  channel={channel}
                  Avatar={CustomAvatar}
                  SendButton={CustomMessageButton}
                >
                  <Window>
                    <div className="widget__stream_chat_header">
                      {user.headshot &&
                        user.headshot.showHeadshot &&
                        user.headshot.headShotPath && (
                          <Avatar
                            image={user.headshot.headShotPath}
                            name={user.info.fullName}
                            shape="circle"
                            size={40}
                          />
                        )}
                      <div className="widget__stream_chat_header_text">
                        <div className="name">{user.info.fullName}</div>
                        <div className="description">{userDescription}</div>
                      </div>
                      <div
                        className="widget__stream_chat_close"
                        onClick={() => setChatOpen(false)}
                      >
                        <img
                          width={16}
                          height={16}
                          src={"/images/icons/close.svg"}
                          alt=""
                          style={{ margin: "auto" }}
                        />
                      </div>
                    </div>
                    <div className="widget__stream_chat_search_bar">
                      <div className="chatSearchBar">
                        <input
                          type="text"
                          onChange={(e) => {
                            setSearchTerm(e.target.value);
                          }}
                          placeholder="Search in Conversation"
                        />
                      </div>
                    </div>

                    {messageSearchTerm &&
                      messageSearchTerm !== "" &&
                      !searchingMessages && (
                        <MessageList
                          messages={searchMessages}
                          jumpToLatestMessage={true}
                          disableDateSeparator={true}
                        />
                      )}
                    {searchingMessages && (
                      <div
                        className="stripe_spinner"
                        style={{ height: "100%", backgroundColor: "#fff" }}
                      >
                        <LoadingIndicator size={32} color="#5469d4" />
                      </div>
                    )}

                    {!(messageSearchTerm && messageSearchTerm !== "") && (
                      <div
                        className="widget__chat_scrollview"
                        ref={sidebarRef}
                        style={{ backgroundColor: "#fff" }}
                      >
                        <MessageList
                          jumpToLatestMessage={false}
                          disableDateSeparator={true}
                        />
                        {channel.data.status &&
                          channel.data.status === "REQUESTED" && (
                            <div className="widget__chat_request_prompt">
                              <div className="widget__chat_request_text">
                                {chatRequestText && (
                                  <div className="widget__chat_request_text">
                                    {chatRequestText}
                                  </div>
                                )}
                              </div>
                              {!isCancelRequest ? (
                                <div
                                  className="widget__chat_request_cancel_button"
                                  onClick={() => setIsCancelRequest(true)}
                                  style={
                                    !user.messageSettings.arePaidChatsEnabled
                                      ? {
                                          marginTop: "0px",
                                        }
                                      : null
                                  }
                                >
                                  Cancel Request
                                </div>
                              ) : (
                                <React.Fragment>
                                  <p className="cancel__text">
                                    Cancel DM request?
                                  </p>
                                  <div
                                    className="widget__chat_request_cancel_button nevermind__btn"
                                    onClick={() => setIsCancelRequest(false)}
                                  >
                                    Nevermind
                                  </div>
                                  <div
                                    className="widget__chat_request_cancel_button cancel__btn"
                                    onClick={cancelChatRequest}
                                  >
                                    Yes, cancel request
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          )}

                        <div ref={messagesEndRef} />
                      </div>
                    )}

                    {chatInitiated &&
                      channel?.data?.status !== "CANCELED" &&
                      currentUser && (
                        <MessageInput
                          maxRows={3}
                          Input={() => (
                            <CustomMessageInput
                              messagesEndRef={messagesEndRef}
                              sidebarRef={sidebarRef}
                            />
                          )}
                        />
                      )}
                    {requestProcessing && (
                      <div
                        className="form__submit"
                        style={{
                          backgroundColor: "#fff",
                          padding: 16,
                          paddingBottom: 24,
                        }}
                      >
                        <button
                          type="button"
                          style={{ backgroundColor: color }}
                          disabled
                        >
                          <span className="loader"></span>
                        </button>
                      </div>
                    )}
                    {showLoginBtn && (
                      <div
                        className="form__submit"
                        style={{
                          backgroundColor: "#fff",
                          padding: 16,
                          paddingBottom: 24,
                        }}
                      >
                        <button
                          type="button"
                          onClick={initiateChat}
                          style={{ backgroundColor: color }}
                        >
                          DM {user.info.fullName}
                        </button>
                      </div>
                    )}
                  </Window>
                </Channel>
              </Chat>
            )}
          </div>
        )}
      </Drawer>
    </Fragment>
  );
}
