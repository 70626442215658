const UserBanner = ({ user, hideMobile }) => {
  const userGradient = `linear-gradient(180deg, ${user?.theme?.gradientBackgroundColors[0]} 0%, ${user?.theme?.gradientBackgroundColors[1]} 100%)`;
  const userColor = user?.theme?.solidBackgroundColor;

  const backgroundStyles =
    user.theme.selectedBackgroundOption === "gradient"
      ? {
          backgroundImage: userGradient,
        }
      : {
          backgroundColor: userColor,
        };

  return (
    <div
      className={`main__image ${hideMobile ? "hide_mobile" : ""}`}
      style={backgroundStyles}
    >
      {user.theme.backgroundImagePath && !user.theme.fadeBackground && (
        <img src={user.theme.backgroundImagePath} alt="background" />
      )}
    </div>
  );
};

export default UserBanner;
