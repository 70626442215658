const CustomMessageUploadIcon = () => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.23773 19.2279L10.7813 12.6314C11.1741 12.2354 11.3706 12.0373 11.597 11.9632C11.7963 11.8979 12.0109 11.8979 12.2101 11.9632C12.4366 12.0373 12.633 12.2354 13.0259 12.6314L19.5258 19.1839M13.8875 13.5L16.7331 10.6314C17.126 10.2354 17.3224 10.0373 17.5489 9.96316C17.7481 9.8979 17.9627 9.8979 18.1619 9.96316C18.3884 10.0373 18.5849 10.2354 18.9777 10.6314L21.8233 13.5M9.91965 7.5C9.91965 8.60457 9.03141 9.5 7.93571 9.5C6.84001 9.5 5.95177 8.60457 5.95177 7.5C5.95177 6.39543 6.84001 5.5 7.93571 5.5C9.03141 5.5 9.91965 6.39543 9.91965 7.5ZM6.74534 19.5H17.0618C18.7285 19.5 19.5618 19.5 20.1984 19.173C20.7584 18.8854 21.2136 18.4265 21.4989 17.862C21.8233 17.2202 21.8233 16.3802 21.8233 14.7V6.3C21.8233 4.61984 21.8233 3.77976 21.4989 3.13803C21.2136 2.57354 20.7584 2.1146 20.1984 1.82698C19.5618 1.5 18.7285 1.5 17.0618 1.5H6.74534C5.07868 1.5 4.24534 1.5 3.60876 1.82698C3.04881 2.1146 2.59355 2.57354 2.30824 3.13803C1.98389 3.77976 1.98389 4.61984 1.98389 6.3V14.7C1.98389 16.3802 1.98389 17.2202 2.30824 17.862C2.59355 18.4265 3.04881 18.8854 3.60876 19.173C4.24534 19.5 5.07868 19.5 6.74534 19.5Z"
        stroke="#545963"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CustomMessageUploadIcon;
