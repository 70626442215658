import { Link, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import { format } from "date-fns";

import {
  fetchServiceFromID,
  fetchPurchasedItem,
  fetchUserInfo,
} from "../../../Services/FirebaseService";
import Video from "../../../Base/Video";
import UserImage from "../../../Base/UserImage";
import UserBanner from "../../../Base/UserBanner";
import SignForm from "../../../Base/Sign/SignForm";
import ModalAuthUser from "../../../Base/ModalAuthUser";
import ImageView from "../../../Base/ImageView";

var productResource = null;
var userInfoResource = null;
var serviceItemResource = null;

export default function ServiceStatus() {
  const [cookies] = useCookies(["username", "uid", "email", "full_name"]);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  const [popup, setPopup] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  let { username, item_id } = useParams();

  if (productResource == null) {
    productResource = fetchPurchasedItem("service", item_id);
  }

  const purchasedProductItem = productResource.read();

  if (!purchasedProductItem.item) {
    window.open("/" + username, "_self");
  }

  if (userInfoResource == null) {
    userInfoResource = fetchUserInfo(purchasedProductItem.uid);
  }

  if (serviceItemResource == null) {
    serviceItemResource = fetchServiceFromID(
      purchasedProductItem.uid,
      purchasedProductItem.item.serviceItemID
    );
  }

  const user = userInfoResource.read();
  const service = purchasedProductItem.item;
  const serviceItem = serviceItemResource.read().service;

  const purchaseDate = new Date(service.id);

  return (
    <>
      <div className="mainMore">
        <UserBanner user={user} hideMobile={true} />

        <Link
          to={"/" + username + "/services"}
          className="mainMore__back remove"
        >
          <img src="/images/icons/arrow-left.svg" alt="" />
        </Link>

        <div className="main__info">
          {currentUser ? (
            <div className="userImage" onClick={() => setPopup(!popup)}>
              <UserImage
                currentUser={currentUser}
                isModalOpen={popup}
                setIsModalOpen={setPopup}
              />
            </div>
          ) : (
            <div
              className="signinbutton"
              onClick={() => setLoginModalVisible(true)}
            >
              Sign in
            </div>
          )}
          {popup && <ModalAuthUser setIsModalOpen={setPopup} />}
        </div>

        {loginModalVisible && (
          <SignForm
            active={loginModalVisible}
            setActive={setLoginModalVisible}
            defaultEmail={signUpEmail}
          />
        )}

        <div className="auto__container">
          <div className="form__outer">
            <div className="form">
              <div className="form__inner">
                <div className="widget__inner">
                  <div className="widget__inner-request">
                    <div className="widget__inner-request-tick">
                      <img src="/images/icons/tick-green.svg" alt="" />
                    </div>
                    <div className="widget__inner-request-title">
                      Purchase Successful
                    </div>

                    <div
                      className="widget__inner-request-text"
                      style={{ color: "#5A6175" }}
                    >
                      {user.info.fullName +
                        " will contact you to deliver this service"}
                    </div>

                    {[serviceItem].map((service) => {
                      return (
                        <Link
                          to={"/" + username + "/products/pages/info"}
                          state={{ service: service, user: user }}
                        >
                          <div key={service.id} className="widget__inner-type">
                            {service.videoPath && (
                              <Video
                                addClass={"widget__inner-type-video"}
                                showFullscreen={true}
                                poster={
                                  "https://image.mux.com/" +
                                  service.videoPath +
                                  "/thumbnail.png"
                                }
                                url={
                                  "https://stream.mux.com/" +
                                  service.videoPath +
                                  ".m3u8"
                                }
                              />
                            )}
                            {service.imagePath && !service.videoPath && (
                              <ImageView
                                addClass={"widget__inner-type-video"}
                                url={service.imagePath}
                              />
                            )}
                            <div className="widget__inner-type-content">
                              <div className="widget__inner-type-tag">
                                Service
                              </div>
                              <div className="widget__inner-type-title">
                                {service.title}
                              </div>
                              <div className="widget__inner-type-text">
                                {service.description}
                              </div>
                              <div
                                className="widget__inner-type-footer"
                                style={{ justifyContent: "flex-start" }}
                              >
                                {user.headshot &&
                                  user.headshot.showHeadshot && (
                                    <div
                                      className="widget__inner-request-info-image"
                                      style={{
                                        height: 41,
                                        width: 41,
                                        borderRadius: 5,
                                      }}
                                    >
                                      <img
                                        src={user.headshot.headShotPath}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                <div>
                                  <div
                                    className="widget__inner-request-info-content-name"
                                    style={{ fontSize: 16, lineHeight: "18px" }}
                                  >
                                    {user.info.fullName}
                                  </div>
                                  <div
                                    className="widget__inner-request-info-content-prof"
                                    style={{
                                      marginBottom: 0,
                                      fontSize: 16,
                                      lineHeight: "18px",
                                    }}
                                  >
                                    {user.info.title +
                                      ", " +
                                      user.info.workPlace}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      );
                    })}

                    <div
                      className="widget__inner-general-title"
                      style={{ marginTop: 36, marginBottom: 18 }}
                    >
                      Details
                    </div>

                    <div className="widget__inner-type">
                      <div className="widget__inner-type-content">
                        <div
                          className="widget__inner-type-text"
                          style={{ color: "#151924" }}
                        >
                          Purchased: {format(purchaseDate, "MMM dd, yyyy")}
                        </div>
                        <div
                          className="widget__inner-type-text"
                          style={{ color: "#151924" }}
                        >
                          Paid:{" "}
                          {service.rate && service.rate > 0
                            ? "$" + service.rate
                            : "FREE"}
                        </div>
                        <div
                          className="widget__inner-type-text"
                          style={{ color: "#0045FF", cursor: "pointer" }}
                        >
                          See receipt
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="widget__inner more"
              style={{ paddingTop: 12, paddingBottom: 12 }}
            >
              {!cookies.uid && (
                <div
                  className="widget__inner-request-footer"
                  style={{ justifyContent: "center" }}
                >
                  <div className="widget__inner-request-footer-title">
                    Create A Free Account
                  </div>
                  <div
                    className="widget__inner-request-footer-text"
                    style={{ maxWidth: "100%" }}
                  >
                    And manage this request on the go
                  </div>
                  <div className={"sign__input"}>
                    <input
                      onChange={(e) => setSignUpEmail(e.target.value)}
                      value={signUpEmail}
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                  <button
                    className="sign__submit"
                    onClick={() => setLoginModalVisible(true)}
                    style={{
                      marginTop: 36,
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: 174,
                    }}
                  >
                    SIGN UP FREE
                  </button>
                  <div
                    className="widget__inner-request-footer-text"
                    style={{
                      maxWidth: "100%",
                      color: "#5A6175",
                      fontSize: 14,
                      marginTop: 16,
                    }}
                  >
                    No Credit Card Required
                  </div>
                </div>
              )}

              {cookies.uid && (
                <div className="widget__inner-request-footer">
                  <div className="widget__inner-request-footer-title">
                    Download the Dossi App!
                  </div>
                  <div className="widget__inner-request-footer-text">
                    Manage your requests directly from your phone.
                  </div>
                  <div className="widget__inner-request-footer-row">
                    <a href="/">
                      <img src="/images/icons/appstore.svg" alt="" />
                    </a>
                    <a href="/">
                      <img src="/images/icons/google.svg" alt="" />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="genBtn" style={{ marginTop: 0, marginBottom: 64 }}>
        Powered by{" "}
        <img
          className="dossifooterlogo"
          src="/images/icons/PoweredbyDossi.svg"
          alt="Logo"
        />
      </div>
    </>
  );
}
